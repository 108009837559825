export default $client => ({
  get() {
    return $client.get('coin-pairs');
  },
  calculate(id, amount) {
    return $client.post(`coin-pairs/${id}/calculate`, {
      amount,
    })
  },
  reverseCalculate(id, amount) {
    return $client.post(`coin-pairs/${id}/reverse-calculate`, {
      amount,
    })
  },
  fields(id) {
    return $client.get(`coin-pairs/${id}/fields`);
  }
});
