import './sass/app.scss'

import Vue from 'vue';
import App from './App.vue'
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';
import VueMobileDetection from "vue-mobile-detection";

import Api from './api';

import store from './store';
import router from './router';

Vue.prototype.$api = new Api();

Vue.use(Vuelidate);
Vue.use(VueClipboard);
Vue.use(VueMobileDetection);

const app = new Vue({
  store,
  router,
  el: '#app',
  render: h => h(App),
});

const accessToken = localStorage.getItem('accessToken');

if (accessToken) {
  Vue.prototype.$api.auth.check(accessToken).then(({data}) => {
    const user = data;
    user.access_token = accessToken;
    store.dispatch('auth/signIn', user);

    if (user.identity_verification) {
      store.commit('exchange/created', user.last_exchange);
      store.commit('identity/verification', user.identity_verification);

      const step = user.has_rejected_identity ? 'setIdentityRejected' : 'setIdentityProcessing';
      store.dispatch('exchange/' + step);
    }
  }).catch(error => {
    // store.dispatch('auth/signOut');
    console.log(error);
  });
}

Vue.prototype.$api.settings.get().then(({data}) => {
  store.dispatch('settings/setMain', data);
})

router.beforeEach((to, from, next) => {
  if (to.name === 'home') {
    store.dispatch('exchange/setExchangeForm');
  }
  next();
})
