import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import auth from './modules/auth';
import exchange from './modules/exchange';
import identity from './modules/identity';
import settings from './modules/settings';

export default new Vuex.Store({
  modules: {
    auth,
    exchange,
    identity,
    settings
  }
});
