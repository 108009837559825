export default $client => ({
  signIn(email, password) {
    return $client.post('auth/signin', {
      email,
      password,
    });

  },
  signUp(email) {
    return $client.post('auth/signup', {
      email,
    });
  },
  check(accessToken) {
    return $client.get('auth/check', {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      }
    })
  },
  setPassword(password) {
    return $client.post('auth/set-password', {
      password,
    })
  },
  getNewCode(email) {
    return $client.post('auth/get-new-code', {
      email,
    });
  },
});
