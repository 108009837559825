import Vue from 'vue';

function userFactory() {
  return {
    name: null,
    email: null,
    has_password: false,
    has_verified_email: false,
    has_verified_identity: false,
    has_rejected_identity: false,
    identity_verification: null,
  }
}

const state = {
  user: userFactory(),
  access_token: null,
};

const mutations = {
  user(state, value) {
    state.user = value;
  },
  accessToken(state, value) {
    state.access_token = value;
  },
  reset(state) {
    state.user = userFactory();
    state.access_token = null;
  }
};

const getters = {
  isLogin(state) {
    return Boolean(state.access_token);
  },
  hasPassword(state) {
    return state.user.has_password;
  },
  hasVerifiedEmail(state) {
    return state.user.has_verified_email;
  },
  hasVerifiedIdentity(state) {
    return state.user.has_verified_identity;
  },
  hasRejectedIdentity(state) {
    return state.user.has_rejected_identity;
  }
};

const actions = {
  signIn({commit}, user,) {
    const accessToken = user.access_token;

    commit('user', user);
    commit('accessToken', accessToken);

    localStorage.setItem('accessToken', accessToken);

    Vue.prototype.$api.client.defaults.headers['Authorization'] = 'Bearer ' + accessToken;
  },
  signOut({commit}) {
    commit('reset');

    localStorage.removeItem('accessToken');

    Vue.prototype.$api.client.defaults.headers['Authorization'] = null;
  }
};

export default {
  namespaced: true,

  state,
  mutations,
  getters,
  actions,
}
