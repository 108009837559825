export default $client => ({
  create(file, group, type = null, meta = null) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('group', group);

    if (Number.isInteger(type)) {
      formData.append('type', type);
    }

    if (meta) {
      for (const property in meta) {
        formData.append(`meta[${property}]`, meta[property]);
      }
    }

    return $client.post('identity/file', formData);
  }
});
