<template>
  <nav class="home-header--nav">
    <router-link
      :to="{ name: 'home' }"
      class="nav--link" :class="{'active': $route.name == 'home' }"
      @click.native="$store.dispatch('exchange/setExchangeForm'); $emit('closeBurgerMenu')"
      v-text="'Home'"
    />
    <!-- <a href="#feature" class="nav--link" @click="$emit('closeBurgerMenu')">Features</a> -->
    <a href="/#feature" class="nav--link" @click="$emit('closeBurgerMenu')">About Us</a>
    <!-- <a href="#services" class="nav--link" @click="$emit('closeBurgerMenu')">Services</a> -->
    <a href="/#contact_us" class="nav--link" @click="$emit('closeBurgerMenu')">Regulations</a>
    <router-link
      :to="{ name: 'faq' }"
      class="nav--link" :class="{'active': $route.name == 'faq' }"
      @click="$emit('closeBurgerMenu')"
      v-text="'F.A.Q.'"
    />
    <router-link
      :to="{ name: 'contactUs' }"
      class="nav--link" :class="{'active': $route.name == 'contactUs' }"
      @click="$emit('closeBurgerMenu')"
      v-text="'Contact Us'"
    />
  </nav>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style scoped lang="scss">
.home-header--nav {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: flex-end;
  padding-right: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media all and (max-width: 1200px) {
    padding-right: 1rem;
  }

  a {
    color: $grey;
    font-size: 16px;
    line-height: 21px;
    margin: 0 .5rem;
    transition: 0.3s;
    text-decoration: none;
    font-weight: 600;
    padding: .5rem;
    border-radius: 20px;

    @media all and (max-width: 1200px) {
      padding: .75rem;
      margin: 0 .25rem;
    }

    &.active {
      padding: .5rem 1.75rem;
      background-color: $grey_bg;
    }
  }

  @media all and (max-width: 960px) {
    padding-right: 0;
  }
}
</style>
