export default $client => ({
  create(files) {
    return $client.post('identity', {files});
  },
  getById(id) {
    return $client.get('identity/' + id);
  },
  applicant: {
    create(attributes) {
      return $client.post('identity/applicant', attributes);
    }
  }
});
