<template>
  <header class="home-header">
    <div class="container">
      <div class="home-header--logo">
        <router-link :to="{ name: 'home' }" @click.native="$store.dispatch('exchange/setExchangeForm')" class="nav--link">
          <img src="/img/logo.svg" alt=""/>
          <!-- <span>MediaSystem</span> -->
        </router-link>
      </div>
      <navbar/>
      <div class="home-header--signin">
        <button class="btn" v-if="isLogin" @click="signOut">LOGOUT</button>
        <button class="btn" v-else @click="signIn">LOGIN</button>
      </div>
      <div class="btn btn--burger" @click="menu = !menu">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div v-if="menu" class="home-header--menu">
        <div class="btn btn--close" @click="menu = !menu">X</div>
        <div class="home-header--signin">
          <button class="btn" v-if="isLogin" @click="signOut">LOGOUT</button>
          <button class="btn" v-else @click="signIn">LOGIN</button>
        </div>
        <navbar @closeBurgerMenu="menu = false"/>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Navbar from "@components/Navbar/Navbar.vue";

export default {
  name: "Header",
  components: {
    Navbar,
  },
  data: () => ({
    menu: false
  }),
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters("auth", ["isLogin"]),
  },
  methods: {
    signIn() {
      this.$router.push({ name: "signIn" });
    },
    signOut() {
      this.$store.dispatch('auth/signOut');
      this.$store.dispatch('exchange/setExchangeForm');
      this.$router.push({ name: 'home' });
    }
  },
};
</script>

<style lang="scss" scoped>
.home-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header_h);

  &--logo {
    font-family: 'Montserrat';
    letter-spacing: 2px;
    text-transform: uppercase;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    span {
      color: $black;
    }

    img {
      margin-right: 12px;
    }

    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }
  }

  &--signin {
    .btn {
      padding: .5rem 3rem;
      color: $red_main;
      font-weight: 700;
      line-height: 23px;
      background-color: $white;
      box-shadow: 0px 0px 16px rgba(173, 180, 180, 0.24);

      @media all and (max-width: 1200px) {
        width: auto;
        padding: .5rem 1.5rem;
      }

      @media (max-height: 700px) {
        height: 2.75rem;
        width: 7rem;
      }
    }
  }

  .btn {
    &--burger {
      display: none;
    }
  }

  &--menu {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .home-header {
    // &--logo {
    //   margin-bottom: 1rem;
    // }
    > .container {
      justify-content: space-between;
    }

    :deep(.home-header--nav) {
      display: none;
    }

    &--signin {
      display: none;
    }

    .btn {
      &--burger {
        display: inline-flex;
      }
    }

    &--menu {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 20rem;
      max-width: 20rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: $black;
      z-index: 999;

      .btn {
        &--close {
          display: inline-flex;
          align-self: flex-end;
          padding: .75rem 1rem;
          margin: 0 0 .5rem;
          font-size: 2rem;
        }
      }

      .home-header--signin {
        display: flex;
        justify-content: flex-end;
        padding: .5rem;
      }

      :deep(.home-header--nav) {
        display: flex;
        flex-grow: 0;

        a {
          width: 100%;
          padding: .25rem 1rem;
          margin: .5rem 0;
        }
      }
    }
  }
}
</style>
