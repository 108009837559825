import client from "./client";

import Coins from './endpoints/coins';
import CoinPairs from './endpoints/coin-pairs';
import Auth from './endpoints/auth';
import Verify from './endpoints/verify';
import Identity from './endpoints/identity';
import IdentityLimit from './endpoints/identity-limit';
import IdentityFiles from './endpoints/identity-files';
import Exchange from './endpoints/exchange';
import Countries from './endpoints/countries';
import Feedback from './endpoints/feedback';
import Settings from './endpoints/settings';

class Api {
  constructor() {
    this.client = client;
    this._loadConfig();
    this._loadEndPoints();
  }

  _loadConfig() {
    this.client.interceptors.response.use(success => success.data, error => {
      return Promise.reject(error.response.data);
    });
  }

  _loadEndPoints() {
    this.coins = Coins(this.client);
    this.coinPairs = CoinPairs(this.client);
    this.auth = Auth(this.client);
    this.verify = Verify(this.client);
    this.identity = Identity(this.client);
    this.identityFiles = IdentityFiles(this.client);
    this.exchange = Exchange(this.client);
    this.countries = Countries(this.client);
    this.feedback = Feedback(this.client);
    this.identityLimit = IdentityLimit(this.client);
    this.settings = Settings(this.client);
  }
}

export default Api;
