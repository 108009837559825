<template>
  <footer id="contact_us" class="home-footer">
    <div class="container">
      <div class="row -top">
        <router-link :to="{ name: 'home' }" class="logo">
          <img src="/img/logo.svg"/>
          <!-- <div class="logo__desc">
            <h2>MediaSystem</h2>
            <h3>Exchange now</h3>
          </div> -->
        </router-link>
      </div>
      <div class="row">
        <div class="col">
          <div class="col__title">Legal</div>
          <a href="/files/terms-of-use.pdf" target="_blank" class="link">
            Terms and Conditions<span class="link__arrow">▶</span>
          </a>
          <a href="/files/privacy_policy.pdf" target="_blank" class="link">
            Privacy Policy<span class="link__arrow">▶</span>
          </a>
          <a href="/files/kyc_aml_policy.pdf" target="_blank" class="link">
            AML KYC Policy<span class="link__arrow">▶</span>
          </a>
          <div class="copyright">
            <div class="copyright__divider"></div>
            <p>© 2022 MediaSystem — Cryptocurrency and Fiat Exchange</p>
          </div>
        </div>
        <div class="col">
          <div class="col__title">Help</div>
          <router-link :to="{ name: 'faq' }" class="link">
            F.A.Q. <span class='link__arrow'>▶</span>
          </router-link>
          <router-link :to="{ name: 'contactUs' }" class="link">
            Contact us <span class='link__arrow'>▶</span>
          </router-link>
        </div>
        <div class="col">
          <div class="col__title">Company info</div>
          <div class="address">
            <p class="address__main">Media System UAB</p>
            <p>Code : 305937916</p>
            <p>Address : Santariškių g. 75-34  Vilnius, Lietuva</p>
          </div>
          <!-- <div class="links">
            <a href="https://www.linkedin.com/company/mediacoin-inc/" target="_blank" class="links__social">
              <IconLinkedIn/>
            </a>
            <a href="https://twitter.com/MediaCoininc" target="_blank" class="links__social">
              <icon-twitter/>
            </a>
            <a href="https://t.me/mediacoinNFT" target="_blank" class="links__social">
              <IconTelegram/>
            </a>
            <a href="https://www.facebook.com/Mediacoin.fb" target="_blank" class="links__social">
              <IconFacebookSecond/>
            </a>
            <a href="https://www.instagram.com/mediacoin.inc" target="_blank" class="links__social">
              <IconInstagram/>
            </a>
          </div> -->
          <!-- <a href="mailto: support@mediasystem.ai" target="_blank" class="links__social">
            <icon-google/>
          </a> -->
        </div>
        <!-- <div class="col">
          <h3>Subscribe to Newsletter</h3>
          <div class="input">
            <input type="text" placeholder="Your email address">
            <div class="btn btn--velvet btn--input">Subscribe</div>
          </div>
        </div> -->
      </div>
      <!-- <div class="row">
        <p class="copy">Copyright © 2020-2021 CryptoShop all rights reserved.</p>
      </div> -->
    </div>
  </footer>
</template>

<script>
import IconLinkedIn from '@components/Icon/IconLinkedIn';
import IconTwitter from '@components/Icon/IconTwitter';
import IconGoogle from '@components/Icon/IconGoogle';
import IconTelegram from '@components/Icon/IconTelegram';
import IconFacebookSecond from '@components/Icon/IconFacebookSecond';
import IconInstagram from '@components/Icon/IconInstagram';
// import IconFacebook from '@components/Icon/IconFacebook';

export default {
  components: {
    IconLinkedIn,
    IconTwitter,
    IconGoogle,
    IconTelegram,
    IconFacebookSecond,
    IconInstagram,
  }
}
</script>

<style lang="scss" scoped>
.home-footer {
  color: $grey_black;
  background-color: $white;

  > .container {
    flex-wrap: wrap;
    padding-top: 3rem;
    padding-top: 4.5vh;
    padding-bottom: 3rem;
    padding-bottom: 4.5vh;
  }

  .row {
    &.-top {
      a {
        text-decoration: none;
        color: inherit;
        line-height: normal;
      }

      .logo {
        display: flex;
        align-items: flex-end;
        margin-bottom: 2rem;

        img {
          width: 14rem;
          height: 2.75rem;
          margin-right: 1rem;
        }

        &__desc {
          text-transform: uppercase;

          h2 {
            margin: 0;
            color: $grey_black;
            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 20px;
            letter-spacing: 2px;
          }

          h3 {
            margin: 0;
            color: #6B6B6B;
            font-family: 'Open Sans';
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }

    .col {
      display: flex;
      min-width: 30%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 .75rem;

      &:nth-child(1) {
        // min-width: 30%;
        padding-left: 0;
      }

      &:nth-child(3) {
        // min-width: 30%;
        padding-right: 0;
      }

      &__title {
        color: $grey_black;
        font-family: 'Montserrat';
        font-weight: 700;
        padding-bottom: 1rem;
      }

      a {
        text-decoration: none;
        color: $red_main;
        line-height: normal;

        &.link {
          display: flex;
          align-items: center;
          margin: 6px 0;

          .link__arrow {
            font-size: 10px;
            padding-left: .5rem;
          }
        }
      }

      p {
        margin: .25rem 0;
        line-height: normal;
      }

      h3 {
        color: $velvet;
        font-size: 1.25rem;
        font-weight: 600;
        padding-bottom: 1.25rem;
        margin: 0;
      }

      .links {
        display: flex;

        &__social {
          width: 3rem;
          height: 3rem;
          margin: .25rem .75rem .25rem 0;
        }

        // &__social {
        //   display: inline-flex;
        //   align-items: center;
        //   justify-content: center;
        //   width: 3rem;
        //   height: 3rem;
        //   margin: .25rem .75rem .25rem 0;
        //   border-radius: 50%;
        //   background-color: $white;

        //   :deep(svg) {
        //     width: 1rem;
        //     height: 1rem;
        //   }

        //   &:hover {
        //     background-color: $velvet;

        //     :deep(svg g) {
        //       mix-blend-mode: color-burn !important;
        //     }
        //   }
        // }
      }

      .address {
        flex-grow: 1;
        padding-bottom: 1rem;
      }

      .input {
        width: 100%;
        display: flex;
        align-items: center;
        color: $black;
        padding: .25rem;
        padding-left: 1rem;
        margin-bottom: .75rem;
        border-radius: 1rem;
        background-color: $white;

        input {
          min-width: 10rem;
          display: flex;
          flex-grow: 1;
          align-items: center;
          height: 100%;
          padding: .75rem .25rem;
          font-size: 1.25rem;
          border: none;
          outline: none;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .copy {
      width: 100%;
      padding-top: 1rem;
      color: $brown;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }

    .copyright {
      padding-top: 2rem;
      color: #858585;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      &__divider {
        width: 3rem;
        height: 4px;
        background-color: #D9D9D9;
        border-radius: 2px;
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      .row {
        .col {
          width: 100%;
          min-width: 100%;
          padding: 0 0 2rem;

          h3 {
            padding-bottom: .75rem;
          }
        }
      }
    }
  }
}
</style>
