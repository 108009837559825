import Vue from 'vue';

const state = {
  exchange: {
    identity_limit_coin_id: null,
    identity_limit_amount: null,
    tx_hash_check_manual_mode: false,
    manual_payment_mode: true,
  }
};

const mutations = {
  exchange(state, value) {
    state.exchange = value;
  },

  setMain(state, data) {
    if (data.exchange) {
        if (data.exchange.hasOwnProperty('tx_hash_check_manual_mode')) {
          state.exchange.tx_hash_check_manual_mode = data.exchange.tx_hash_check_manual_mode
        }

        if (data.exchange.hasOwnProperty('manual_payment_mode')) {
          state.exchange.manual_payment_mode = data.exchange.manual_payment_mode
        }
    }
  }
};

const getters = {
  identityLimitAmount(state) {
    return Number(state.identity_limit_amount);
  },
};

const actions = {
  setMain({commit}, data) {
    commit('setMain', data);
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  getters,
  actions,
}
