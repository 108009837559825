export default $client => ({
  create(coinPairId, amount, fields) {
    return $client.post('exchange', {
      amount,
      fields,
      coin_pair_id: coinPairId,
    });
  },
  paid(exchangeId, txId) {
    return $client.post(`exchange/${exchangeId}/paid`, {
      txid: txId
    });
  },
  getByHash(hash) {
    return $client.get(`exchange/${hash}`);
  },
  setUserDeposit(payload) {
    return $client.post(`exchange/user-deposit`, payload);
  },
  invoiceCreate(payload) {
    return $client.post(`exchange/invoice-create`, payload);
  },
  getHashByUuid(uuid) {
    return $client.get(`exchange/get-hash-by-uuid/${uuid}`);
  },
  getCredentials(exchangeId) {
    return $client.get(`exchange/${exchangeId}/payment-credentials`);
  },

  submitPaid(hash) {
    return $client.post(`exchange/${hash}/submit`)
  },

  cancelPaid(hash) {
    return $client.post(`exchange/${hash}/cancel`)
  },
});
