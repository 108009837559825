<template>
  <div class="wrapper">
    <v-header/>
    <router-view/>
    <v-footer/>
  </div>
</template>

<script>
import VHeader from "@components/Header/Header";
import VFooter from "@components/Footer/Footer";

export default {
  name: "App",
  components: {
    VHeader,
    VFooter,
  },
};
</script>


<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
