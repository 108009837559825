const PENDING = 0;
const APPROVED = 1;
const REJECTED = 2;

const state = {
  verification: {},
  steps: {
    iteration: 0,
    components: [
      'add-card',
      'add-document',
      'add-selfie',
    ],
  },
  files: [],
  applicant: {
    name: null,
    dob: null,
    country: null,
  },
  error: {
    applicant: {
      name: null,
      dob: null,
      country: null,
      docs: null
    },
    card: {
      number: null,
      photo: null,
    },
  },
  meta: {
    card_number: null,
  },
  loading: false,
};

const mutations = {
  verification(state, value) {
    state.verification = value;
  },
  loading(state, value) {
    state.loading = value;
  },
  applicant(state, value) {
    state.applicant = value
  },
  updateApplicant(state, value) {
    Object.assign(state.applicant, value)
  },
  updateMeta(state, value) {
    Object.assign(state.meta, value)
  },
  meta(state, value) {
    state.meta = value
  },
  addFile(state, hash) {
    state.files.push(hash);
  },
  resetFiles(state) {
    state.files = [];
  },
  resetDocsFiles(state) {
    state.files = [state.files[0]];
  },
  verify(state, payload) {
    if (payload.step === 'add-card') {
      const check = {
        number() {
          state.error.card.number = /^\d{4}-\d{4}/.test(state.meta.card_number)
            ? '' : 'Set card numbers'
        },
        photo() {
          state.error.card.photo = state.files.length < 1
            ? 'Upload card photo' : null
        }
      }

      if (payload.field === 'all') {
        Object.entries(state.error.card).forEach(([key, value]) => { check[key]() });
      } else {
        check[payload.field]()
      }
    }

    if (payload.step === 'add-document') {
      const check = {
        name() {
          state.error.applicant.name = !state.applicant.name || state.applicant.name.length < 3
            ? 'Set fullname' : null
        },
        dob() {
          if (/^\d{4}-\d{2}-\d{2}/.test(state.applicant.dob)) {
            state.error.applicant.dob = null
            const { 0: year , 1: month, 2: day } = state.applicant.dob.split("-");

            if (+month > 12) {
              state.error.applicant.dob = 'Incorrect date'
              return
            }

            if (+day > 31) {
              state.error.applicant.dob = 'Incorrect date'
              return
            }

            const minDob = new Date(+year + 16, month, day);
            const current = new Date();
            if (minDob.getTime() > current.getTime()) {
              state.error.applicant.dob = 'Incorrect date'
            }
          } else state.error.applicant.dob = 'Incorrect date'
        },
        country() {
          state.error.applicant.country = !state.applicant.country
            ? 'Set country' : null
        },
        docs() {
          state.error.applicant.docs = state.files.length < 3
            ? 'Upload documents' : null
        }
      }

      if (payload.field === 'all') {
        Object.entries(state.error.applicant).forEach(([key, value]) => { check[key]() });
      } else {
        check[payload.field]()
      }
    }
  },
};

const getters = {
  files(state) {
    return state.files;
  },
  component(state) {
    return state.steps.components[state.steps.iteration];
  },
  components(state) {
    return state.steps.components;
  },
  loading(state) {
    return state.loading;
  },
  isLast(state) {
    const limit = state.steps.components.length - 1;
    return limit === state.steps.iteration;
  },
  isAllowNextStep(state, getters) {
    const rules = [1, 3, 4,];

    const countFiles = state.files.length;
    const stepIteration = state.steps.iteration;

    let res = countFiles >= rules[stepIteration];
    if (res && getters.component === 'add-card') {
      Object.entries(state.error.card).forEach(([key, value]) => {
        if (value) {
          res = false
          return
        }
      });
    }
    if (res && getters.component === 'add-document') {
      Object.entries(state.error.applicant).forEach(([key, value]) => {
        if (value) {
          res = false
          return
        }
      });
    }

    return res;
  },
  hasPendingStatus(state) {
    return state.verification.status === PENDING;
  },
  hasRejectedStatus(state) {
    return state.verification.status === REJECTED;
  }
};

const actions = {
  next({state}) {
    const limit = state.steps.components.length - 1;

    if (state.steps.iteration < limit) {
      state.steps.iteration++
    }
  },
  prev({state}) {

    if (state.steps.iteration > 0) {
      state.steps.iteration--;
    }

  }
};

export default {
  namespaced: true,

  state,
  mutations,
  getters,
  actions,
}
