import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home'),
    },
    {
      path: '/exchanges/:hash',
      name: 'exchange',
      component: () => import('./views/Exchange/Exchange'),
    },
    {
      path: '/widget',
      name: 'widget',
      component: () => import('./views/Home'),
    },
    {
      path: '/features',
      name: 'features',
      component: () => import('./views/Feature'),
    },
    {
      path: '/integration',
      name: 'integration',
      component: () => import('./views/Integration'),
    },
    {
      path: '/contact-us',
      name: 'contactUs',
      component: () => import('./views/ContactUs'),
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('./views/FAQ'),
    },
    {
      path: '/main-news',
      name: 'mainNews',
      component: () => import('./views/News/Main'),
    },
    {
      path: '/secondary-one-news',
      name: 'secondaryOneNews',
      component: () => import('./views/News/SecondaryOne'),
    },
    {
      path: '/secondary-two-news',
      name: 'secondaryTwoNews',
      component: () => import('./views/News/SecondaryTwo'),
    },
    {
      path: '/auth/sign-in',
      name: 'signIn',
      component: () => import('./views/Auth/SignIn'),
    }
  ],
});

export default router;

