import Vue from 'vue';
import router from '../../router';

export const EXCHANGE_FORM = 0;
export const VERIFY_EMAIL = 1;
export const IDENTITY_VERIFY_WARNING = 2;
export const SET_PASSWORD = 3;
export const IDENTITY_VERIFY_BEFORE_START = 4;
export const IDENTITY_VERIFY = 5;
export const IDENTITY_VERIFY_PROCESSING = 6;
export const EXCHANGE_DETAIL = 7;

export const IDENTITY_VERIFY_REJECTED = 8;

const state = {
  amount: null,
  rate: null,
  identityLimit: false,
  coinPairId: null,
  fields: process.env.NODE_ENV === 'production' ? {} : {
    fullname: 'Demo Demo Demo',
    card: '5375 4114 0000 0000',
    expire: '10/24',
    country: 'UA',
    sending_wallet: '0xbe345fc0c18A78e28c88Df5C859d4998a6c731F9',
    btc_receive_wallet: '1Awyd1QWR5gcfrn1UmL8dUBj2H1eVKtQhg',
  },
  created: {},
  steps: {
    iteration: EXCHANGE_FORM,
    components: [
      'exchange-form',
      'verify-email',
      'identity-verify-warning',
      'set-password',
      'identity-verify-before-start',
      'identity-verify',
      'identity-verify-processing',
      'exchange-detail',
      'identity-verify-rejected',
    ]
  },
  isLoading: false,
  transactionAddress: null,
  transactionPayLink: null,
};

const mutations = {
  field(state, instance) {
    state.fields[instance.name] = instance.value;
  },
  amount(state, value) {
    state.amount = value;
  },
  identityLimit(state, value) {
    state.identityLimit = value;
  },
  coinPairId(state, value) {
    state.coinPairId = value
  },
  created(state, value) {
    state.created = value;
  },
  transactionAddress(state, value) {
    state.transactionAddress = value;
  },
  unsetTransactionAddress(state) {
    state.transactionAddress = null
  },
  transactionPayLink(state, value) {
    state.transactionPayLink = value
  }
};

const getters = {
  component(state) {
    return state.steps.components[state.steps.iteration];
  },
  isAllowCreate(state, getters, rootState, rootGetters) {
    if (! state.identityLimit && rootGetters["auth/hasVerifiedEmail"]) {
      return true;
    }

    if (state.identityLimit && rootGetters["auth/hasVerifiedIdentity"]) {
      return true;
    }

    return false;
  }
};

const actions = {
  create({state}) {
    state.isLoading = true;

    Vue.prototype.$api.exchange
      .create(state.coinPairId, state.amount, state.fields)
      .then(response => {
        state.created = response.data;

        if (state.steps.iteration !== IDENTITY_VERIFY_PROCESSING) {
          router.push({name: 'exchange', params: {hash: state.created.hash}});
        }
      })
      .finally(() => state.isLoading = false);
  },
  next({state, dispatch, getters, rootGetters}) {
    const limit = state.steps.components.length - 1;

    if (state.steps.iteration > limit) {
      return;
    }

    if (getters.isAllowCreate) {
      dispatch('create');
      return;
    }

    const nextStep = state.steps.iteration + 1;

    // If the user email has been verified, but the "isAllowCreate" getter returned "false",
    // then we consider that the next step is identity verification.
    if (nextStep === VERIFY_EMAIL && rootGetters["auth/hasVerifiedEmail"]) {
      state.steps.iteration = IDENTITY_VERIFY_WARNING;
      return;
    }

    if (nextStep === SET_PASSWORD && rootGetters["auth/hasPassword"]) {
      state.steps.iteration = IDENTITY_VERIFY_BEFORE_START;
      return
    }

    if (nextStep === IDENTITY_VERIFY_PROCESSING) {
      dispatch('create')
    }

    state.steps.iteration = nextStep;
  },
  prev({state}) {
    if (state.steps.iteration > 0) {
      state.steps.iteration--;
    }
  },
  setExchangeForm({state, dispatch, getters, rootGetters}) {
    // if (rootGetters['identity/hasPendingStatus'] || rootGetters['identity/hasRejectedStatus']) {
    //   return;
    // }
    state.steps.iteration = EXCHANGE_FORM;
  },
  setIdentityRejected({state}) {
    state.steps.iteration = IDENTITY_VERIFY_REJECTED;
  },
  setIdentityProcessing({state}) {
    state.steps.iteration = IDENTITY_VERIFY_PROCESSING;
  },
  setIdentityVerify({state}) {
    state.steps.iteration = IDENTITY_VERIFY;
  },
  setTransactionAddress({state, commit}, transactionAddress) {
    commit('transactionAddress', transactionAddress)
  },
  setTransactionPayLink({ state, commit }, link) {
    commit('transactionPayLink', link)
  }
};

export default {
  namespaced: true,

  state,
  mutations,
  getters,
  actions,
}
